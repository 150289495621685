import NavGrid, { GridItem, NavGridItemStill } from './generic/NavGrid';
import React from 'react';
import Section from './generic/Section';
import { graphql } from 'gatsby';
import LearnEmphasizedGridBanner from './LearnEmphasizedGridBanner';
import getRoute from '../routes/getRoute';

import { ContentfulArticle, ContentfulBase, ContentfulSimpleArticle } from 'types';
import { Stack } from './generic/Stack';
import InitialMargin from './generic/InitialMargin';
import { NavGridLink } from './PwpNavGrid';

type Props = {
  pageData: { sections: Array<ContentfulSimpleArticle | ContentfulArticleGridRepresentation> };
};
export default function LearnGrid(props: Props) {
  return (
    <Section size="max">
      <InitialMargin />
      <Stack size="medium">
        <NavGrid>
          {props.pageData.sections.filter(detectSimpleArticle).map(section => {
            return (
              <LearnEmphasizedGridBanner
                key={section.id}
                heading={section.heading || ''}
                body={section.body}
                image={section.images?.[0].image}
              />
            );
          })}
        </NavGrid>
        <NavGrid mapRowHeightToColumnWidth>
          {props.pageData.sections.filter(detectLearnEmphasizedBanner).map((section, index) => {
            switch (section.__typename) {
              case 'ContentfulArticle':
                const link = getRoute(section);
                return (
                  <GridItem key={index} gridSize={section.gridSize}>
                    <NavGridLink to={link}>
                      <NavGridItemStill
                        objectFit="cover"
                        heading={section.shortHeading ? section.shortHeading : section.heading || ''}
                        description={section.description?.description || ''}
                        image={section.thumbnail}
                        gridSize={section.gridSize}
                        gridImagePad
                      />
                    </NavGridLink>
                  </GridItem>
                );
              default:
                return null;
            }
          })}
        </NavGrid>
      </Stack>
    </Section>
  );
}

export type ContentfulArticleGridRepresentation = Omit<ContentfulArticle, 'articleSections'> & { id: string };

export const articleGridRepresentationFragment = graphql`
  fragment ContentfulArticleGridRepresentation on ContentfulArticle {
    __typename
    id
    slug
    heading
    shortHeading
    thumbnail {
      ...ImageBase
      gatsbyImageData(layout: CONSTRAINED, width: 640, placeholder: NONE)
    }
    description {
      description
    }
    gridSize
  }
`;

function detectLearnEmphasizedBanner(section: unknown): section is ContentfulArticleGridRepresentation {
  return (
    Boolean(section) &&
    typeof section === 'object' &&
    '__typename' in (section as ContentfulBase) &&
    (section as ContentfulBase).__typename === 'ContentfulArticle'
  );
}

function detectSimpleArticle(section: unknown): section is ContentfulSimpleArticle {
  return (
    Boolean(section) &&
    typeof section === 'object' &&
    '__typename' in (section as ContentfulBase) &&
    (section as ContentfulBase).__typename === 'ContentfulSimpleArticle'
  );
}
