import { Image as ImageType, RichText as RichTextType } from '../types';
import { branding, universalMargin } from 'styles/variables';
import Image from './generic/Image';
import React from 'react';
import RichText from './generic/RichText';
import { mediaQueries } from '../styles/breakpoints';
import styled, { css } from 'styled-components';

interface ResourcesHeroProps {
  heading: string;
  body: RichTextType;
  image: ImageType;
}

export default function LearnEmphasizedGridBanner({ heading, body, image }: ResourcesHeroProps) {
  return (
    <ResourceRoot>
      <ResourceContainer>
        <TextContainer>
          <h4>{heading}</h4>
          <RichText>{body}</RichText>
        </TextContainer>
        <ImageContainer>
          <ImageAnimation>
            <Image
              image={image?.gatsbyImageData}
              alt={image?.description}
              objectFit="cover"
              shape="cardInnerRoundedCorners"
            />
          </ImageAnimation>
        </ImageContainer>
      </ResourceContainer>
    </ResourceRoot>
  );
}

const ResourceRoot = styled.li`
  grid-column: span 6;
  ${mediaQueries.medium(css`
    grid-column: span 8;
  `)}
  ${mediaQueries.large(css`
    grid-column: span 12;
  `)}
`;

const ResourceContainer = styled.div`
  display: flex;
  position: relative;
  background: ${branding.cardGrid.item.default.background};
  flex-direction: column-reverse;
  border-radius: ${branding.boxBorderRadius};
  ${mediaQueries.medium(css`
    flex-direction: row;
  `)}
  ${mediaQueries.large(css`
    flex-direction: row;
    &:nth-child(5n + 2) {
      flex-direction: row-reverse;
    }
  `)}
`;

const ImageAnimation = styled.div`
  border-radius: ${branding.innerBorderRadius};
  height: 300px;
  overflow: hidden;
  ${mediaQueries.small(css`
    height: 100%;
  `)}
  > * {
    height: 100%;
    width: 100%;
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

const ContentContainer = styled.div`
  overflow: hidden;
  ${mediaQueries.small(css`
    flex: 0 0 50%;
  `)}
`;

const TextContainer = styled(ContentContainer)`
  padding: ${universalMargin * 2}px;
  ${mediaQueries.small(css`
    padding: ${universalMargin * 4}px ${universalMargin * 2}px;
  `)}
`;

const ImageContainer = styled(ContentContainer)`
  padding: ${universalMargin * 2}px;
`;
