import React from 'react';
import styled from 'styled-components';
import { mediaQueries, universalMargin } from 'styles';

export default function InitialMargin() {
  return <TopMargin role="presentation" />;
}

const TopMargin = styled.div`
  margin-top: ${universalMargin * 2}px;
  ${mediaQueries.small(`margin-top: ${universalMargin * 4}px;`)}
  ${mediaQueries.large(`margin-top: ${universalMargin * 8}px;`)}
`;
