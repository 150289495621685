import NavGrid, { GridItem, NavGridItemStill } from './generic/NavGrid';
import { Link } from 'gatsby';
import { Product, ProductCategory } from 'context/Quote/typedefs';
import NavGridItemHero from './generic/NavGrid/NavGridItemHero';
import { ProductAvailability } from '../constants/contentful';
import React from 'react';
import Section from './generic/Section';
import { getProductCategoryPath } from '../routes/products';
import getRoute from '../routes/getRoute';
import styled from 'styled-components';
import { black } from 'styles/baseColors';
import { ContentfulPage, PageSections } from 'types';
import { includeFutureProducts } from 'utils';

interface PwpNavGridProps {
  id?: string;
  products?: Array<Product | ProductCategory>;
  productGridTitle?: string;
  productGridDescription?: string;
}

export default function PwpNavGrid({ id, products, productGridTitle, productGridDescription }: PwpNavGridProps) {
  return (
    <Section size="max" id={id}>
      <NavGrid mapRowHeightToColumnWidth>
        {productGridTitle && (
          <NavGridItemHero
            heading={productGridTitle}
            description={productGridDescription ? productGridDescription : ''}
          />
        )}
        {products?.filter(productFilter).map((product, index) => (
          <GridItem key={`${product.id} - ${index}`} gridSize={'gridSize' in product ? product.gridSize : 'Default'}>
            <GridItemByType item={product} />
          </GridItem>
        ))}
      </NavGrid>
    </Section>
  );
}

/** Which products to display */
const productFilter = (product: Product | ProductCategory) =>
  product.__typename === 'ContentfulProductCategory' ||
  product.availability === ProductAvailability.Available ||
  product.availability === ProductAvailability.Released ||
  (product.availability === ProductAvailability.Future && includeFutureProducts);

interface GridItemByTypeProps {
  item: Product | ProductCategory | (ContentfulPage & PageSections);
}

function GridItemByType({ item }: GridItemByTypeProps) {
  switch (item.__typename) {
    case 'ContentfulProductCategory': {
      return (
        <NavGridLink to={getProductCategoryPath(item)}>
          <NavGridItemStill
            objectFit="contain"
            heading={item.shortHeading || item.heading}
            description={item.description?.description}
            image={item.images?.[0]?.image}
            gridSize={item.gridSize}
            gridLayout={item.gridLayout}
            highlight={item?.highlight}
          />
        </NavGridLink>
      );
    }

    case 'ContentfulProduct': {
      return (
        <NavGridLink key={item.id} to={getRoute(item)}>
          <NavGridItemStill
            objectFit="contain"
            heading={item.shortHeading || item.heading}
            image={item.gridImages?.[0]?.image}
            description={item.description?.description}
            gridSize={item.gridSize}
            gridLayout={item.gridLayout}
            highlight={item?.highlight}
            availability={item.availability}
          />
        </NavGridLink>
      );
    }

    default:
      return null;
  }
}

export const NavGridLink = styled(Link)`
  text-decoration: none !important;
  &:hover {
    color: ${black} !important;
  }
`;
