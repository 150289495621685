import Head from '../components/Head';
import React from 'react';
import LearnGrid, { ContentfulArticleGridRepresentation } from '../components/LearnGrid';
import { graphql, PageProps } from 'gatsby';
import { ContentfulSimpleArticle, PwpImage, RichText } from 'types';

type ResourcesPageData = {
  resources: {
    __typename: 'ContentfulPage';
    contentful_id: string;
    heading: string;
    thumbnail: PwpImage;
    description: RichText;
    sections: Array<ContentfulSimpleArticle | ContentfulArticleGridRepresentation>;
  };
};

export default function Resources({ data: { resources }, location }: PageProps<ResourcesPageData>) {
  const title = resources.heading;
  const description = resources.description;
  const imageUrl = resources.thumbnail?.image?.file.url;

  return (
    <>
      <Head location={location} title={title} description={description} image={imageUrl} />
      <LearnGrid pageData={resources} />
    </>
  );
}

export const query = graphql`
  query {
    resources: contentfulPage(contentful_id: { eq: "21dHJDWnZPETSKXQOcuJP5" }) {
      __typename
      contentful_id
      heading
      thumbnail {
        ...PwpImage
      }
      description {
        raw
      }
      sections {
        ...SimpleArticle
        ...ContentfulArticleGridRepresentation
      }
    }
  }
`;
